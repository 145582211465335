import request from '@/utils/request'
const CancelToken = request.CancelToken;

export function dict(parameter) {
  return request({
    url: 'dictItemManagement/list',
    method: 'post',
    data: {
      body: { dictCode: parameter }
    }
  })
}

export function byenum(parameter) {
  return request({
    url: 'enum/findByCode',
    method: 'post',
    data: {
      'body': parameter
    }
  })
}

// post
export function postAction(url, parameter) {
  return request({
    url: url,
    method: 'post',
    data: parameter
  })
}

// post method= {post | put}
export function httpAction(url, parameter, method) {
  return request({
    url: url,
    method: method || 'post',
    data: parameter
  })
}

export function pendingAction(url, parameter, that, method) {
  return request({
    url: url,
    method: method || 'post',
    data: parameter,
    cancelToken: new CancelToken(function (c) {
      that.terminate = c;
    })
  })
}

// put
export function putAction(url, parameter) {
  return request({
    url: url,
    method: 'put',
    data: parameter
  })
}

// get
export function getAction(url, parameter) {
  return request({
    url: url,
    method: 'get',
    params: parameter
  })
}

// deleteAction
export function deleteAction(url, parameter) {
  return request({
    url: url,
    method: 'delete',
    params: parameter
  })
}
