import axios from 'axios';
import {
  MessageBox,
  Message,
  Loading,
  Notification
} from 'element-ui';
import store from '@/store';
import router from '@/router';

const service = axios.create({
  baseURL: window._CONFIG['apiUrl']
});
service.CancelToken = axios.CancelToken;

let notifyInstance = null;
let tiemr = null;

async function resetTimer() {
  if (tiemr) {
    clearTimeout(tiemr);
    tiemr = null;
  }
  tiemr = setTimeout(() => {
    notifyInstance = Notification.warning({
      title: '系统提示',
      message: '登录信息即将过期，请重新登录',
      duration: 0,
      onClose: function () {
        notifyInstance = null;
      }
    });
  }, 1000 * 60 * 30);
}

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['token'] = store.getters.token;
      !notifyInstance && resetTimer();
    }
    return config;
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data;
    console.groupEnd();
    res.code=parseInt(res.code)
    res.code=parseInt(res.errorCode)
    if (res.code !== 0) {
      if (res.errorCode === 8) {
        if (notifyInstance) {
          notifyInstance.close();
          notifyInstance = null;
        }
        if (tiemr) {
          clearTimeout(tiemr);
          tiemr = null;
        }
        if (window.loadingInstance) {
          window.loadingInstance.text = '登录超时，请重新登录！';
        } else {
          window.loadingInstance = Loading.service({
            lock: true,
            text: '登录超时，请重新登录！',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
        }
        MessageBox.confirm('登录超时，请重新登录！', '温馨提示', {
          type: 'warning',
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false
        }).then(() => {
          store.dispatch('user/logout').then(() => {
            router.replace({
              path: '/redirect/login',
              query: {
                redirect: router.history.current.fullPath
              }
            });
          });
        })
      } else {
        Message({
          message: res.errorMsg || res.msg || '系统异常',
          type: 'error',
          duration: 2000
        });
      }
      return Promise.reject(res);
    }
    return Promise.resolve(res);
  },
  error => {
    return Promise.reject(error);
  }
)

export default service;
